.App-header {
  align-items: left;
  color: white;
  font-size: calc(10px + 2vmin);
  height: auto;
  padding: 1rem;
}
.spinner {
  height: 100px;
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
}
.close-escaner{
  left: 140px;
  top: 50px;
  z-index: 10;
}
#interactive.viewport > canvas, #interactive.viewport > video {
  margin-bottom: 0px !important;
}
.escan-qr p{
  font-size: 14px !important;
}
@media (max-width:577px){
  .escan-lote button.escan-qr, .escan-lote input.escan-qr{
    width: 100% !important;
  } 
}
.button-otros{
  border-left: 2px solid #072548 !important;
}
.mb-20{
  margin-bottom: 20px !important;
}
.text-left{
  text-align: left !important;
}
